@import "@siemens/ix-icons/dist/css/ix-icons.css";
@import "@siemens/ix/dist/siemens-ix/siemens-ix.css";
@import "@siemens/ix-brand-theme/dist/ix-brand-theme/ix-brand-theme.css";

.app-container {
  --container-padding-mobile: 16px;

  @media (max-width: 767px) {
    padding-left: var(--container-padding-mobile);
    padding-right: var(--container-padding-mobile);
  }
}

//For mobile view adjustments
.row {
  @media (max-width: 992px) {
    margin-left: 0;
    margin-right: 0;
  }
}
